<template>

<div class="weather-container">

    <div class="weather-title"><img src="@/assets/images/icon-weather.svg" alt="">風速・風向</div>

    <div class="wind-container">
        <ol class="wind-speed">
            <li><div class="wind-speed-label">最小</div><div class="wind-speed-num">{{formatDecimal(data.windSpeed.min)}}<span class="wind-speed-unit" v-if="data.windSpeed.min!==null">m</span></div></li>
            <li><div class="wind-speed-label average">平均</div><div class="wind-speed-num">{{formatDecimal(data.windSpeed.average)}}<span class="wind-speed-unit" v-if="data.windSpeed.average!==null">m</span></div></li>
            <li><div class="wind-speed-label max">最大</div><div class="wind-speed-num">{{formatDecimal(data.windSpeed.max)}}<span class="wind-speed-unit" v-if="data.windSpeed.max!==null">m</span></div></li>
        </ol>

        <div class="wind-direction">
            <img src="@/assets/images/wind-direction.svg" alt="" v-bind:style="{ transform: 'rotate('+direction+'deg)' }" v-if="direction!==null">
            <span class="wind-speed-num" v-if="direction==null">欠測</span>
            <div class="wind-direction-label">{{direction_name}}</div>
        </div>
    </div>

</div>

</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      direction: null,
      direction_name: "",
      direction_list: [
        "北",
        "北北東",
        "北東",
        "東北東",
        "東",
        "東南東",
        "南東",
        "南南東",
        "南",
        "南南西",
        "南西",
        "西南西",
        "西",
        "西北西",
        "北西",
        "北北西",
        "北"
      ]
    }
  },
  methods: {
    formatDecimal(num) {
      if(num == null){
        return "欠測"
      }
      return num.toFixed(1)
    },
    setDirection() {
      const d = this.data.windDirection
      if(d == null || d > 360){
        this.direction = null
      }else {
        let dindex = Math.round( d / 22.5 )
        this.direction = dindex * 22.5
        this.direction_name = this.direction_list[dindex]
      }
    }
  },
  mounted() {
    this.setDirection()
  }
}
</script>
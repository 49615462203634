<template>

    <div class="row row-height no-gutters">
      
        <div class="col-12 col-xl-6 camera-border">

            <div class="date-container">
                <div class="date mt-0" v-if="currentImage">
                    {{getDate(currentImage.datetime, "MM/DD")}}
                    <span class="date-week">({{getDate(currentImage.datetime, "ddd")}})</span>
                    {{getDate(currentImage.datetime, "HH:mm")}}<span class="date-min">のデータ</span>
                </div>
            </div>

            <div class="camera-main">
                <img v-bind:src="currentImage.url" alt="河川カメラ画像" @error="replaceImage" v-if="currentImage&&currentImage.url!==null">
                <img src="@/assets/images/noimage.jpg" alt="河川カメラ画像" v-if="currentImage&&currentImage.url==null">
            </div>

            <div class="camera-control">

              <div class="camera-play">
                  <button class="camera-play-btn" v-bind:class="{ pause: timerOn }" v-on:click="start()"></button>
              </div>

              <div class="camera-range">

                  <div class="camera-label" v-if="tab==0">
                      <span class="left">1時間前</span>
                      <span class="middle" v-bind:style="{ left: ((100/5)*2) + '%' }">30分前</span>
                      <span class="right">現在</span>
                  </div>

                  <div class="camera-label" v-if="tab==1">
                      <span class="left">12時間前</span>
                      <span class="middle" v-bind:style="{ left: ((100/11)*6) + '%' }">6時間前</span>
                      <span class="right">1時間前</span>
                  </div>

                  <div class="camera-label" v-if="tab==2">
                      <span class="left">24時間前</span>
                      <span class="middle" v-bind:style="{ left: ((100/11)*6) + '%' }">18時間前</span>
                      <span class="right">13時間前</span>
                  </div>

                  <vue-slider
                    v-model="slider_data"
                    :adsorb="true"
                    :interval="1"
                    :max="max"
                    :marks="true"
                    :silent="true"
                    :tooltip="tooltip"
                    :dot-size="20"
                    :dot-style="dotStyle"
                    :rail-style="railStyle"
                    :process-style="processStyle"
                    :hide-label="true"
                    @drag-start="dragStart"
                    @change="change"
                    ref="slider"
                  ></vue-slider>


                </div>

            </div>
          

        </div>

        <div class="col-12 col-xl-6">

            <div class="camera-archive">
                <div>
                    <div class="common-tab camera">
                        <button class="tab-btn" v-on:click="setArchive(0)" v-bind:class="{ active: tab==0 }">1時間以内</button>
                        <button class="tab-btn" v-on:click="setArchive(1)" v-bind:class="{ active: tab==1 }">1～12時間前</button>
                        <button class="tab-btn" v-on:click="setArchive(2)" v-bind:class="{ active: tab==2 }">13～24時間前</button>
                    </div>

                    <div class="camera-images">
                        <div class="row camera-divider no-gutters" v-if="archive">
                            <div class="col-4" v-for="(item, index) in archive" :key="item.datetime" v-on:click="setImage(index)">
                                <div class="camera-images-date" v-bind:class="{ active: (max-index)==slider_index }">{{formatMinute(item.datetime)}}</div>
                                <img v-bind:src="item.url" alt="河川カメラ画像" @error="replaceImage" v-if="item.url!==null">
                                <img src="@/assets/images/noimage.jpg" alt="河川カメラ画像" v-if="item.url==null">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
import moment from "moment"
import VueSlider from "vue-slider-component"
import "vue-slider-component/theme/default.css"
import noimage from "@/assets/images/noimage.jpg"

export default {
  components: {
    VueSlider
  },
  props: ["data"],
  data() {
    return {
      tab: 0,
      max: 6,
      archive: [],
      slider_data: 0,
      slider_index: 0,
      tooltip: "none",
      timerOn: false,
      timerObj: null,
      minutely: null,
      hourly: null,
      currentImage: null,
      railStyle: {
        borderRadius: "0",
        backgroundColor: "#333333",
      },
      processStyle: {
        backgroundColor: "#333333",
      },
      dotStyle: {
        backgroundColor: "#fff",
        border: "3px solid #1457a0",
      },
    }
  },
  methods: {
    setImage(i) {
      this.clear()
      this.$refs.slider.setValue(this.max-i)
      this.currentImage = this.archive[i]
      this.slider_index = this.max-i
    },
    setArchive(t) {
      this.tab = t

      if(t == 0) {
        this.archive = this.minutely
      }else if(t == 1) {
        this.archive = this.hourly[0].slice()
      }else if(t == 2) {
        this.archive = this.hourly[1].slice()
      }
      
      this.max = this.archive.length - 1
      this.setImage(0)
    },
    getArchive(ary) {
      if(this.tab == 0){
        let cnv = JSON.parse(JSON.stringify(ary))
        cnv.shift()
        return cnv;
      }
      return ary
    },
    getSliderList(list) {
      let result = []
      for(let j in list) {
        result.push(Number(j))
      }
      return result.reverse()
    },
    count() {
      if(this.$refs.slider.getValue() == this.max){
        this.clear()
      }else {
        this.$refs.slider.setValue(this.$refs.slider.getValue() + 1)
      }
    },
    start() {

      if(this.$refs.slider.getValue() == this.max){
        this.$refs.slider.setValue(0)
      }

      if(!this.timerOn){
        this.timerObj = setInterval(() => {
          this.count()
        }, 1000)
        this.timerOn = true
      }else {
        this.clear()
      }

    },
    dragStart() {
      this.clear()
    },
    change() {
      this.currentImage = this.archive[this.max-this.$refs.slider.getValue()]
      this.slider_index = this.$refs.slider.getValue()
    },
    clear() {
      clearInterval(this.timerObj)
      this.timerOn = false
    },
    sliceByNumber(arr, number) {
      const length = Math.ceil(arr.length / number)
      return new Array(length).fill().map((_, v) => arr.slice(v * number, (v + 1) * number))
    },
    formatMinute(date) {
      return moment(date).format("MM/DD HH:mm")
    },
    getDate(date, format) {
      return moment(date).format(format)
    },
    replaceImage(e) {
      e.target.src = noimage
    }
  },
  mounted() {

    if(this.data){
      this.minutely = JSON.parse(JSON.stringify(this.data.points[0].minutelyArchive))
      this.archive = this.minutely
      this.max = this.archive.length - 1
      this.$refs.slider.setValue(this.max)
      this.hourly = this.sliceByNumber(this.data.points[0].hourlyArchive, 12)
      this.currentImage = this.minutely[0]
    }

  }
}
</script>
<template>
<div>
    <div class="map-title">観測地点</div>
    <div id="map"></div>
    <div class="map-name">野方大橋水位局</div>
</div>
</template>

<script>
import  "leaflet/dist/leaflet.css"
import  L from "leaflet"
require( 'leaflet.defaultextent' )
import  'leaflet.defaultextent/dist/leaflet.defaultextent.css'

delete  L.Icon.Default.prototype._getIconUrl

export default {
    mounted() {
        const map = L.map( "map", { center: L.latLng( 35.13181, 137.02840 ), zoom: 18, defaultExtentControl: true } ).addLayer(
            L.tileLayer( "https://{s}.tile.osm.org/{z}/{x}/{y}.png" )
        )
        
        const svgIcon = L.divIcon({
            html: '<svg xmlns="https://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><defs><style>.cls-1, .cls-3 {opacity: 0.5;}.cls-2, .cls-3 {fill: #ed1c24;}</style></defs><g id="レイヤー_2" data-name="レイヤー 2"><g id="レイヤー_1-2" data-name="レイヤー 1"><g><g class="cls-1"><path class="cls-2" d="M13,1A12,12,0,1,1,1,13,12,12,0,0,1,13,1m0-1A13,13,0,1,0,26,13,13,13,0,0,0,13,0Z"/></g><circle class="cls-3" cx="13" cy="13" r="8"/></g></g></g></svg>',
            className: "",
            iconSize: [26, 26],
            iconAnchor: [13, 26],
        });
        L.marker([35.13181, 137.02840], {interactive: false, icon: svgIcon}).addTo(map)
    }
}
</script>
<template>
<div class="home">

    <div class="sp-header">
        <div class="sp-top">
            <a href="."><img src="@/assets/images/sp-logo.svg" alt="日進市 雨量・水位観測システム"></a>
            <a href="" class="reload-btn"></a>
        </div>
    </div>

    <ol class="sp-navi" v-bind:class="{ up:!is_navi, down:is_navi }" ref="sp_navi">
        <li v-scroll-to="{ el: '#river', offset: -60 }">
            <div class="sp-navi-title-container">
                <div class="sp-navi-title">
                    <img src="@/assets/images/icon-river.svg" alt="">河川水位
                </div>
            </div>
            <div class="sp-navi-common">
                <div class="sp-navi-alert" v-bind:class="`lv${river_level}`">
                    <span v-if="river_level==null">欠 測</span>
                    <span v-if="river_level==0">平常水位</span>
                    <span v-if="river_level==1">注意水位</span>
                    <span v-if="river_level==2">危険水位</span>
                </div>
                <div class="sp-navi-msg"><div class="sp-navi-msg-inner">{{river_msg_sp}}</div></div>
            </div>
        </li>
        <li v-scroll-to="{ el: '#rain', offset: -60 }">
            <div class="sp-navi-title-container">
                <div class="sp-navi-title">
                    <img src="@/assets/images/icon-rain.svg" alt="">雨量観測
                </div>
            </div>
            <div class="sp-navi-common">
                <div class="sp-navi-alert" v-bind:class="`lv${rain_level}`">
                    <span v-if="rain_level==null">欠測</span>
                    <span v-if="rain_level==0">平常雨量</span>
                    <span v-if="rain_level==1">注意雨量</span>
                    <span v-if="rain_level==2">警戒雨量</span>
                </div>
                <div class="sp-navi-msg"><div class="sp-navi-msg-inner">{{rain_msg}}</div></div>
            </div>
        </li>
        <li v-scroll-to="{ el: '#camera', offset: -60 }">
            <div class="sp-navi-title-container">
                <div class="sp-navi-title">
                    <img src="@/assets/images/icon-camera.svg" alt="">河川カメラ
                </div>
            </div>
            <div class="sp-navi-camera">
                <div class="sp-navi-aspect">
                  <div class="sp-navi-aspect-img">
                    <img v-bind:src="json.camera.points[0].minutelyArchive[0].url" alt="河川カメラ画像" v-if="json&&json.camera.points[0].minutelyArchive[0].url!==null" @error="replaceImage">
                    <img src="@/assets/images/noimage.jpg" alt="河川カメラ画像" v-if="json&&json.camera.points[0].minutelyArchive[0].url==null">
                  </div>
                </div>
            </div>
        </li>
    </ol>

    <div class="container-full" v-bind:style="{padding:sp_navi_padding+'px 0 0 0'}">
        <nav>

            <a href="." class="logo">
                <img src="@/assets/images/logo.svg" alt="日進市 雨量・水位観測システム">
            </a>

            <ol class="navi">
                <li v-scroll-to="'#river'" class="pointer" v-bind:class="{ active: (navi_active=='river')?true:false }">
                      <div class="navi-border">
                          <div class="navi-title"><img src="@/assets/images/icon-river.svg" alt="">河川水位</div>
                          
                          <div class="navi-level-container">
                              <div class="navi-level" v-bind:class="`lv${river_level}`">
                                  <div class="navi-level-icon" v-bind:class="`lv${river_level}`"></div>
                                  <div class="navi-level-label">
                                      <span v-if="river_level==null">欠 測</span>
                                      <span v-if="river_level==0">平 常<br>水 位</span>
                                      <span v-if="river_level==1">注 意<br>水 位</span>
                                      <span v-if="river_level==2">危 険<br>水 位</span>
                                  </div>
                              </div>
                              <div class="navi-msg"><span>{{river_msg}}</span></div>
                          </div>
                    </div>
                </li>
                <li v-scroll-to="'#rain'" class="pointer" v-bind:class="{ active: navi_active=='rain' }">
                    <div class="navi-border">
                        <div class="navi-title">
                            <img src="@/assets/images/icon-rain.svg" alt="">雨量観測
                        </div>

                        <div class="navi-level-container">
                            <div class="navi-level" v-bind:class="`lv${rain_level}`">
                                  <div class="navi-level-icon" v-bind:class="`lv${rain_level}`"></div>
                                  <div class="navi-level-label">
                                      <span v-if="rain_level==null">欠 測</span>
                                      <span v-if="rain_level==0">平 常<br>雨 量</span>
                                      <span v-if="rain_level==1">注 意<br>雨 量</span>
                                      <span v-if="rain_level==2">警 戒<br>雨 量</span>
                                  </div>
                            </div>
                            <div class="navi-msg"><span>{{rain_msg}}</span></div>
                        </div>
                    </div>
                </li>
                <li v-scroll-to="'#camera'" class="pointer" v-bind:class="{ active: navi_active=='camera' }">
                    <div class="navi-border">
                        <div class="navi-title">
                            <img src="@/assets/images/icon-camera.svg" alt="">河川カメラ
                        </div>
                        <div class="navi-camera">
                            <img v-bind:src="json.camera.points[0].minutelyArchive[0].url" alt="河川カメラ画像" v-if="json&&json.camera.points[0].minutelyArchive[0].url!==null" @error="replaceImage">
                            <img src="@/assets/images/noimage.jpg" alt="河川カメラ画像" v-if="json&&json.camera.points[0].minutelyArchive[0].url==null">
                        </div>
                    </div>
                </li>
                <li>
                    <Weather :data="json.weather" v-if="json" />
                </li>
                <li>
                    <Map />
                </li>
            </ol>

            <router-link to="/disclaimer" class="term-btn">免責事項</router-link>
            
        </nav>

        <div class="main">

            <div class="category" id="river"><h2><img src="@/assets/images/icon-river.svg" alt="">河川水位</h2></div>

            <div class="row gutter-50">
                <div class="col-12 col-xl-6 mt-20 category-margin">
                    <div class="common-container">
                        <RiverLevel :data="json.river" v-if="json" />
                    </div>
                </div>

                <div class="col-12 col-xl-6 mt-20">
                    <div class="common-container">
                        <RiverChart :data="json.river" v-if="json" />
                    </div>
                </div>
            </div>

            <div class="category" id="rain" ref="rain"><h2><img src="@/assets/images/icon-rain.svg" alt="">雨量観測</h2></div>

            <div class="row">
                <div class="col-12 col-xl-6 mt-20 category-margin">
                    <div class="common-container">
                        <RainChart :data="json.rain" v-if="json" />
                    </div>
                </div>

                <div class="col-12 col-xl-6 mt-20">
                    <div class="common-container">
                        <RainLedger :data="json.rain" v-if="json" />
                    </div>
                </div>
            </div>

            <div class="category" id="camera" ref="camera"><h2><img src="@/assets/images/icon-camera.svg" alt="">河川カメラ</h2></div>

            <div class="row">
                <div class="col-12 mt-20 category-margin">
                    <div class="common-container camera">
                        <Camera :data="json.camera" v-if="json" />
                    </div>
                </div>
            </div>

            <div class="row d-block d-sm-none mt-20">
                <div class="col-12">
                    <WeatherSp :data="json.weather" v-if="json" />
                </div>
            </div>

            <div class="row d-block d-sm-none mt-20">
                <div class="col-12">
                    <MapSp />
                </div>
            </div>

            <div class="row d-block d-sm-none mt-20">
                <div class="col-12">
                    <router-link to="/disclaimer" class="term-btn">免責事項</router-link>
                </div>
            </div>

        </div>

    </div>

</div>
</template>

<script>

import Vue from 'vue'
import Map from '@/components/Map.vue'
import MapSp from '@/components/MapSp.vue'
import Weather from '@/components/Weather.vue'
import WeatherSp from '@/components/WeatherSp.vue'
import RiverChart from '@/components/RiverChart.vue'
import RiverLevel from '@/components/RiverLevel.vue'
import RainChart from '@/components/RainChart.vue'
import RainLedger from '@/components/RainLedger.vue'
import Camera from '@/components/Camera.vue'
import VueScrollTo from 'vue-scrollto'
import {NaviMixin} from '@/mixins/navi.js'

Vue.use(VueScrollTo, {
  offset: -20,
})

export default {
  mixins: [NaviMixin],
  name: 'Home',
  components: {
    Weather,
    WeatherSp,
    Map,
    MapSp,
    RiverChart,
    RiverLevel,
    RainChart,
    RainLedger,
    Camera
  },
  props: ['scrollTarget'],
  data() {
    return {
      is_navi: true,
      sp_navi_padding: null,
      scrollY: 0
    }
  },
  watch: {
    scrollY (val) {
      if (window.matchMedia('(max-width: 576px)').matches) {
        this.is_navi = (val < 48)?true:false
      }else {
        if(this.$refs.rain && this.$refs.camera){
          const rain_top = this.$refs.rain.getBoundingClientRect().top
          const camera_top = this.$refs.camera.getBoundingClientRect().top
            
          if(val >= 0 && val < rain_top){
            this.navi_active = "river"
          }else if(val >= rain_top && val < camera_top) {
            this.navi_active = "rain"
          }else if(val >= camera_top){
            this.navi_active = "camera"
          }
        }
      }
    }
  },
  methods: {
    onScroll() {
      this.$set(this, 'scrollY', window.pageYOffset)
    },
    getNaviHeight () {
      if (window.matchMedia('(max-width: 576px)').matches) {
        if(this.$refs.sp_navi){
          this.sp_navi_padding = this.$refs.sp_navi.clientHeight + 50
        }
      }else {
        this.sp_navi_padding = 0
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
    this.getNaviHeight()
    window.addEventListener('resize', this.getNaviHeight)
  }
}
</script>

<template>

<div class="map-container">

    <div class="map-title">気象センサー</div>

    <div class="wind-sp-container">
        <div class="wind-sp-inner">

            <div class="wind-sp-left">
                <div class="wind-sp-title"><img src="@/assets/images/icon-weather.svg" alt="">風速・風向</div>

                <ol class="wind-sp-speed">
                  <li>
                    <div class="wind-speed-label">最小</div>
                    <div class="wind-speed-num">{{formatDecimal(data.windSpeed.min)}}<span class="wind-speed-unit" v-if="data.windSpeed.min!==null">m</span></div>
                  </li>
                  <li>
                    <div class="wind-speed-label average">平均</div>
                    <div class="wind-speed-num">{{formatDecimal(data.windSpeed.average)}}<span class="wind-speed-unit" v-if="data.windSpeed.average!==null">m</span></div>
                  </li>
                  <li>
                    <div class="wind-speed-label max">最大</div>
                    <div class="wind-speed-num">{{formatDecimal(data.windSpeed.max)}}<span class="wind-speed-unit" v-if="data.windSpeed.max!==null">m</span></div>
                  </li>
                </ol>
            </div>

            <div class="wind-sp-right">
                <img src="@/assets/images/sp_wind_direction.svg" alt="" v-bind:style="{ transform: 'rotate('+direction+'deg)' }" v-if="direction!==null">
                <span class="wind-speed-em" v-if="direction==null">欠測</span>
                {{direction_name}}
            </div>

        </div>
    </div>

</div>

</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      direction: null,
      direction_name: "",
      direction_list: [
        "北",
        "北北東",
        "北東",
        "東北東",
        "東",
        "東南東",
        "南東",
        "南南東",
        "南",
        "南南西",
        "南西",
        "西南西",
        "西",
        "西北西",
        "北西",
        "北北西",
        "北"
      ]
    }
  },
  methods: {
    formatDecimal(num) {
      if(num == null){
        return "欠測"
      }
      return num.toFixed(1)
    },
    setDirection() {

      const d = this.data.windDirection
      let from = 0
      let to = 11.25

      if(d == null){
        this.direction = null
      
      }else {

        if(d >= 360){
          this.direction = 0
          this.direction_name = this.direction_list[0]
        }else {
          
          for (let i = 0; i < this.direction_list.length; i++) {
            if(d >= from && d < to) {
              this.direction = (from + to) / 2
              this.direction_name = this.direction_list[i]
              break
            }
            from = to
            to += 22.5
          }

        }

      }
      
    }
  },
  mounted() {
    this.setDirection()
  }
}
</script>
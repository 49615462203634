<template>
<div>

    <div class="date-container">
        <div class="date">
            <div>
                {{updated[0]}}<span class="date-week">({{updated[1]}})</span>{{updated[2]}}<span class="date-min">のデータ</span>
            </div>
        </div>
    </div>

    <div class="common-tab">
        <button class="tab-btn" v-on:click="setArchive(0)" v-bind:class="{ active: tab==0 }">1時間雨量</button>
        <button class="tab-btn" v-on:click="setArchive(1)" v-bind:class="{ active: tab==1 }">10分雨量</button>
    </div>

    <div class="common-inner" ref="inner">
      
        <rain-bar-chart v-if="loaded" :chart-data="datacollection" :options="options" :tab="tab" :styles="{width:w+'px', height: h+'px', position: 'relative'}"></rain-bar-chart>
        <ol class="legend">
            <li><div class="legend-line rain"></div>雨量</li>
            <li v-if="!tab"><div class="legend-line caution"></div>1時間注意雨量(20mm)</li>
            <li v-if="!tab"><div class="legend-line danger"></div>1時間警戒雨量(40mm)</li>
        </ol>

        <div class="wl-num-container">

            <div class="wl-num">
                <div class="rain-current-label">
                    {{!tab?"1時":"10分"}}間雨量
                </div>
                <div class="rain-current-val">
                    <span v-if="!tab">{{(data.points[0].hourly!==null)?formatDecimal(data.points[0].hourly):"---"}}<span class="wl-current-unit">mm</span></span>
                    <span v-else>{{(data.points[0].minutely!==null)?formatDecimal(data.points[0].minutely):"---"}}<span class="wl-current-unit">mm</span></span>
                </div>
            </div>

            <div class="wl-num">
                <div class="rain-current-label continuous">
                    <span v-if="continuous_date!==null">{{continuous_date}}からの雨量</span>
                    <span v-else>連続雨量</span>
                </div>
                <div class="rain-current-val">
                    {{(continuous!==null)?formatDecimal(continuous):"---"}}<span class="wl-current-unit">mm</span>
                </div>
            </div>
    
            <div class="wl-num">
                <div class="rain-current-label">
                    24時間雨量
                </div>
                <div class="rain-current-val">
                    {{(data.points[0].daily!==null)?formatDecimal(data.points[0].daily):"---"}}<span class="wl-current-unit">mm</span>
                </div>
            </div>

        </div>

    </div>

    
</div>
</template>

<script>

import Vue from "vue"
import RainBarChart from "./RainBarChart.js"
import moment from "moment"

export default {
    props: ["data"],
    components: {
        RainBarChart
    },
    data () {
        return {
            datacollection: { labels:[], datasets: [] },
            options: null,
            tab: 0,
            updated: [],
            minutely_label: [],
            minutely_data: [],
            hourly_label: [],
            hourly_data: [],
            minutely_last: null,
            hourly_last: null,
            last_time: null,
            h: 300,
            w: 350,
            hourly_max: null,
            minutely_max: null,
            continuous_date: null,
            continuous: null,
            loaded: false
        }
    },
    methods: {
        formatDecimal(num) {
            if(num == null){
                return null
            }
            return num.toFixed(1)
        },
        setArchive(t) {
          
            this.tab = t
            this.options.tab = t

            if(t){

                this.datacollection = {
                    labels: this.minutely_label,
                    datasets: [{
                        data: this.minutely_data,
                        backgroundColor: "#4070E9",
                        pointBackgroundColor: "#4070E9",
                        fill: false,
                    }]
                }
                this.options.last_time = this.minutely_last
                
                // 最大値の仮データ
                if(this.minutely_max < 20){
                    this.options.scales.yAxes[0].ticks.max = 20
                }else {
                    delete this.options.scales.yAxes[0].ticks.max
                }

            }else {

                this.datacollection = {
                    labels: this.hourly_label,
                    datasets: [{
                        data: this.hourly_data,
                        backgroundColor: "#4070E9",
                        pointBackgroundColor: "#4070E9",
                        fill: false,
                    }]
                }
                this.options.last_time = this.hourly_last

                // 最大値の仮データ
                if(this.hourly_max < 50){
                    this.options.scales.yAxes[0].ticks.max = 50
                }else {
                    delete this.options.scales.yAxes[0].ticks.max
                }
            }

            Vue.set(this.options)

        },
        // 連続雨量のタイトル
        setContinuous() {
            let latest = this.data.points[0].totalArchive[0]
            if(latest && latest.endTime == null && latest.startTime && latest.data){
                this.continuous_date = moment(latest.startTime).format("HH:mm")
                this.continuous = latest.data
            }
        },
        setChartHeight() {

            if(this.$refs.inner){
                const rect = this.$refs.inner.getBoundingClientRect()
                if(window.matchMedia("(max-width: 576px)").matches){
                    this.w = rect.width - 20
                }else {
                    this.w = rect.width*0.88
                }
            }

            if(window.matchMedia("(max-width: 576px)").matches){
                this.h = 200
            }else {
                this.h = 300
            }
        }
    },
    mounted() {
        
        this.loaded = false
        
        if(this.data){
        
            this.updated[0] = moment(this.data.updatedAt).format("MM/DD")
            this.updated[1] = moment(this.data.updatedAt).format("ddd")
            this.updated[2] = moment(this.data.updatedAt).format("HH:mm")
            this.setContinuous();

            // 10分
            for(let i in this.data.points[0].minutelyArchive){
                this.minutely_label.push(moment(this.data.points[0].minutelyArchive[i].datetime).format("HH:mm"))
                this.minutely_data.push(this.data.points[0].minutelyArchive[i].data)
                if(this.minutely_max < this.data.points[0].minutelyArchive[i].data){
                    this.minutely_max = this.data.points[0].minutelyArchive[i].data
                }
            }

            // 最後のデータに10分追加
            this.minutely_last = moment(this.data.points[0].minutelyArchive[0].datetime).add(10, "m").format("HH:mm")

            this.minutely_label.reverse()
            this.minutely_data.reverse()

            // 1時間
            for(let m in this.data.points[0].hourlyArchive){
                this.hourly_label.push(moment(this.data.points[0].hourlyArchive[m].datetime).format("HH:mm"))
                this.hourly_data.push(this.data.points[0].hourlyArchive[m].data)

                if(this.hourly_max < this.data.points[0].hourlyArchive[m].data){
                    this.hourly_max = this.data.points[0].hourlyArchive[m].data
                }
            }


            // 最後のデータに1時間追加
            this.hourly_last = moment(this.data.points[0].hourlyArchive[0].datetime).add(1, "h").format("HH:00")

            this.hourly_label.reverse()
            this.hourly_data.reverse()

            this.datacollection = {
                labels: this.hourly_label,
                datasets: [{
                    data: this.hourly_data,
                    backgroundColor: "#4070E9",
                    pointBackgroundColor: "#4070E9",
                    fill: false,
                }]
            }

            this.options = {
              tab: 0,
              last_time: this.hourly_last,
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false
              },
              layout: {
                padding: {
                  left: 0,
                  right: 10,
                  top: 35,
                  bottom: 0
                }
              },
              tooltips: {
                callbacks: {
                  label: (tooltipItem) => {
                    return " "+ parseFloat(tooltipItem.value).toFixed(1) +" mm";
                  }
                }
              },
              scales: {
                xAxes: [{
                  ticks: {
                    maxRotation: 0,
                    fontSize: 8,
                    fontColor: "rgba(0, 0, 0, 0)",
                    fontFamily: "'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'Osaka', 'ＭＳ Ｐゴシック', 'MS P Gothic', 'sans-serif'",
                  },
                  gridLines: {
                    zeroLineColor: "rgba(0, 0, 0, 0)",
                    display: true,
                    color: "rgba(0, 0, 0, 0)"
                  }
                }],
                yAxes: [
                  {
                    ticks: {
                      fontSize: 8,
                      maxTicksLimit: 20,
                      fontColor: "#575757",
                      fontFamily: "'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'Osaka', 'ＭＳ Ｐゴシック', 'MS P Gothic', 'sans-serif'",
                      beginAtZero: true, 
                      stepSize: 5,
                      callback: function(value) {
                        return value + ".0"
                      }
                    },
                    gridLines: {
                      display: false
                    }
                  }
                ]
              }
            }

            if(this.hourly_max < 50){
                this.options.scales.yAxes[0].ticks.max = 50
            }

            this.setChartHeight()
            window.addEventListener("resize", this.setChartHeight)

            this.loaded = true
          
        }

    }
}
</script>
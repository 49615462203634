<template>

    <div class="common-inner">

        <div class="date-container">
            <div class="date">
                {{update_day}}<span class="date-week">({{update_week}})</span>{{update_time}}<span class="date-min">のデータ</span>
            </div>
        </div>

        <div class="river-container" v-bind:style="{fontSize:font_size+'px'}">

            <div class="river-img-unit">(m)</div>

            <div class="river-scale-container">
                <div class="river-scale-0">0.00</div>
                <div class="river-scale-1">-1.00</div>
                <div class="river-scale-2">-2.00</div>
                <div class="river-scale-3">-3.00</div>
                <div class="river-scale-4">-4.00</div>
            </div>
            
            <div class="river-img-container">
                <div class="river-img-aspect">
                    <div class="water-line" v-bind:style="getWaterLine(data.points[0].current)"></div>
                    <div class="river-img">
                        <img src="@/assets/images/river1_img.svg" alt="" ref="getParentWidth">
                    </div>
                </div>
            </div>

        </div>

        <ol class="legend mt-15">
            <li><div class="legend-wl"></div>現在の水位</li>
            <li><div class="legend-line caution"></div>注意水位（{{formatDecimal(data.points[0].cautionLv)}}m）</li>
            <li><div class="legend-line danger"></div>危険水位（{{formatDecimal(data.points[0].dangerLv)}}m）</li>
        </ol>
    
        <div class="wl-num-container">

            <div class="wl-num">
                <div class="wl-current-label">
                    現在の水位
                </div>
                <div class="wl-current-val">
                    <img src="@/assets/images/wl-arrow.svg" alt="" v-bind:class="{up: data.points[0].diff>0, down: data.points[0].diff<0}" v-if="data.points[0].diff!==null&&data.points[0].current!==null">
                    {{formatDecimal(data.points[0].current)}}<span class="wl-current-unit" v-if="data.points[0].current!==null">m</span>
                    <span v-else>欠測</span>
                </div>
            </div>

            <div class="wl-num">
                <div class="wl-current-label type2">
                    前回差分
                </div>
                <div class="wl-current-val type2">
                    {{formatDecimal(data.points[0].diff)}}<span class="wl-current-unit" v-if="data.points[0].diff!==null">m</span>
                    <span v-else>欠測</span>
                </div>
            </div>
 
            <div class="wl-num" :class='`lv${current_level}`'>
                <div class="wl-current-label type3">
                    次のレベルまで
                </div>
                <div class="wl-current-val type3" v-if="current_level >= 2">
                    ---<span class="wl-current-unit">m</span>
                </div>
                <div v-else class="wl-current-val type3">
                    {{next}}<span class="wl-current-unit" v-if="next!==null">m</span>
                    <span v-else>欠測</span>
                </div>
            </div>


        </div>

    </div>

</template>

<script>
import moment from "moment"

export default {
  props: ["data"],
  data() {
    return {
      next: null,
      current_level: null,
      font_size: 12,
      update_day: null,
      update_week: null,
      update_time: null
    }
  },
  methods: {
    formatDecimal(num) {
      if(num == null){
        return null
      }
      return num.toFixed(2)
    },
    getWaterLine(num) {
      if(num !== null){
        let line = (num + 4) * ((1-0.125)/4) * 100 + 12.5 - 1
        return {height: line+"%"}
      }
      return 0
    },
    getNextNum(num) {

      if(num == null){
        this.current_level = null
      
      }else {

        // 平常水位
        if(num < this.data.points[0].cautionLv) {
          this.current_level = 0
          this.next = Math.round((this.data.points[0].cautionLv + -(num)) * 100) / 100
          this.next = this.next.toFixed(2)
        // 注意水位
        }else if(num >= this.data.points[0].cautionLv && num < this.data.points[0].dangerLv) {
          this.current_level = 1
          this.next = Math.round((this.data.points[0].dangerLv + -(num)) * 100) / 100
          this.next = this.next.toFixed(2)
        // 危険水位
        }else if(num >= this.data.points[0].dangerLv) {
          this.next = null
          this.current_level = 2
        }
      }

    },
    getTargetWidth () {
      if(this.$refs.getParentWidth){
        let width = this.$refs.getParentWidth.clientWidth
        this.font_size = Math.round(width / 40);
        if(this.font_size < 10) {
          this.font_size = 10
        }else if(this.font_size > 14) {
          this.font_size = 14
        }
      }
    }
  },
  mounted() {
    if(this.data){
      this.update_day = moment(this.data.updatedAt).format("MM/DD")
      this.update_week = moment(this.data.updatedAt).format("ddd")
      this.update_time = moment(this.data.updatedAt).format("HH:mm")
      this.getNextNum(this.data.points[0].current)
    }

    this.getTargetWidth()
    window.addEventListener("resize", this.getTargetWidth)
  }
};
</script>



import { Bar, mixins } from "vue-chartjs"
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["options","tab"],
  watch: {
    chartData: function() {
      this.$data._chart.options = this.options;
      this.renderChart(this.chartData, this.options)
    }
  },
  mounted () {

    this.addPlugin({
      beforeLayout: function (c) {

        let width = c.chart.width
        let size = Math.round(width / 40)
        
        if(size < 10) {
          size = 10
        }else if(size > 14) {
          size = 14
        }
        // フォントサイズの調整
        c.scales["y-axis-0"].options.ticks.fontSize = size

      },
      beforeDraw: function (c) {

        let tab = c.chart.options.tab
        let chart = c.chart
        let xscale = chart.scales["x-axis-0"]
        let yscale = chart.scales["y-axis-0"]
        let width = chart.width
        let size = Math.round(width / 40)
        
        if(size < 10) {
          size = 10
        }else if(size > 14) {
          size = 14
        }

        chart.ctx.font = size+"px 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'Osaka', 'ＭＳ Ｐゴシック', 'MS P Gothic', 'sans-serif'"
        chart.ctx.fillStyle = "#575757"
 
        if (window.matchMedia("(max-width: 576px)").matches) {
          chart.ctx.fillText("（mm/"+((tab == 0)?"1時間":"10分")+"）", 0, 25)
        }else {
          chart.ctx.fillText("（mm/"+((tab == 0)?"1時間":"10分")+"）", -5, 20)
        }
        
        let g = chart.ctx.createLinearGradient(chart.chartArea.left, chart.chartArea.top, chart.chartArea.left, yscale.height)

        g.addColorStop(0, "#e0f5ff")
        g.addColorStop(1, "#ffffff")

        chart.ctx.fillStyle = g
        chart.ctx.fillRect(chart.chartArea.left, chart.chartArea.top, xscale.width, yscale.height)

        let top = yscale.getPixelForValue(yscale.max)
        let btm = yscale.getPixelForValue(yscale.min)
        let w = xscale.width / chart.scales["x-axis-0"]._ticks.length

        // X軸のグリッド
        for(let i = 0; i <= chart.scales["x-axis-0"]._ticks.length; i++){
          chart.ctx.beginPath()
          chart.ctx.lineWidth = ((i%3 == 0)?1.5:1)
          chart.ctx.setLineDash((i%3 == 0)?[]:[2, 2])
          chart.ctx.moveTo(chart.chartArea.left+(w*i), top)
          chart.ctx.lineTo(chart.chartArea.left+(w*i), btm)
          chart.ctx.strokeStyle = "#cccccc"
          chart.ctx.stroke()
        }

        // Y軸のグリッド
        for(let p in chart.scales["y-axis-0"]._ticks){

          chart.ctx.beginPath()

          let line = yscale.getPixelForValue(chart.scales["y-axis-0"]._ticks[p].value)

          if(chart.scales["y-axis-0"]._ticks[p].value%10 !== 0){
            chart.scales["y-axis-0"]._ticks[p].label = ""
            chart.ctx.setLineDash([2, 2])
            chart.ctx.lineWidth = 1
          }else {
            chart.ctx.setLineDash([])
            chart.ctx.lineWidth = 1.5
          }

          // 一番上の線
          if(p == 0) {
            chart.ctx.setLineDash([])
            chart.ctx.lineWidth = 1.5
          }

          chart.ctx.moveTo(chart.chartArea.left, line)
          chart.ctx.lineTo(chart.chartArea.right, line)
          chart.ctx.strokeStyle = "#cccccc"
          chart.ctx.stroke()

          // 横線2
          chart.ctx.beginPath()
          let line2 = yscale.getPixelForValue(chart.scales["y-axis-0"]._ticks[p].value)
          chart.ctx.lineWidth = ((chart.scales["y-axis-0"]._ticks[p].value%10 !== 0)?1:1.5)
          chart.ctx.setLineDash([])
          chart.ctx.moveTo(chart.chartArea.left-5, line2)
          chart.ctx.lineTo(chart.chartArea.left, line2)
          chart.ctx.strokeStyle = "#cccccc"
          chart.ctx.stroke()
        }

      },
      afterDatasetsDraw: function (c) {

        let tab = c.chart.options.tab
        let chart = c.chart
        let yscale = chart.scales["y-axis-0"]
        let width = chart.width
        let size = Math.round(width / 40)
        
        if(size < 10) {
          size = 10
        }else if(size > 14) {
          size = 14
        }

        chart.ctx.font = size+"px 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'Osaka', 'ＭＳ Ｐゴシック', 'MS P Gothic', 'sans-serif'"
        chart.ctx.fillStyle = "#575757"

        let labels = []
        for(let j in chart.scales["x-axis-0"].ticks) {
          labels.push(chart.scales["x-axis-0"].ticks[j])
        }

        let cnt = chart.scales["x-axis-0"]["_gridLineItems"].length - 1
        let measure

        let scale_pad = 12
        if (window.matchMedia("(max-width: 576px)").matches) {
          scale_pad = 8
        }

        for(let i in chart.scales["x-axis-0"]["_gridLineItems"]) {
          
          measure = chart.ctx.measureText(labels[i])
          
          if(labels[i]){
            if(i%3 == 0){
              chart.ctx.fillText(labels[i], chart.scales["x-axis-0"]["_gridLineItems"][i].tx2 - (measure.width/2), chart.scales["x-axis-0"]["_gridLineItems"][i].ty2 + scale_pad)
            }
          }

          if(i == cnt && (labels[cnt] !==c.chart.options.last_time)){
            chart.ctx.fillText(c.chart.options.last_time, chart.scales["x-axis-0"]["_gridLineItems"][i].tx2 - (measure.width/2), chart.scales["x-axis-0"]["_gridLineItems"][i].ty2 + scale_pad)
          }
        
        }

        if(tab == 0){
          // 注意
          let caution_line = yscale.getPixelForValue(20)
          chart.ctx.lineWidth = 2
          chart.ctx.beginPath()
          chart.ctx.setLineDash([])
          chart.ctx.moveTo(chart.chartArea.left, caution_line)
          chart.ctx.lineTo(chart.chartArea.right, caution_line)
          chart.ctx.strokeStyle = "#f4aa18"
          chart.ctx.stroke()

          // 警戒
          let danger_line = yscale.getPixelForValue(40)
          chart.ctx.lineWidth = 2
          chart.ctx.beginPath()
          chart.ctx.setLineDash([])
          chart.ctx.moveTo(chart.chartArea.left, danger_line)
          chart.ctx.lineTo(chart.chartArea.right, danger_line)
          chart.ctx.strokeStyle = "#f53833"
          chart.ctx.stroke()
        }

      }
    });

    this.renderChart(this.chartData, this.options)

  }
}
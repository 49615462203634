<template>
<div class="maintenance-container">

    <div class="maintenance-header">
        <div class="maintenance-top">
            <a href="."><img src="@/assets/images/sp-logo.svg" alt="日進市 雨量・水位観測システム"></a>
            <a href="" class="reload-btn"></a>
        </div>
    </div>

    <div class="maintenance-inner">
        <div class="maintenance-title">現在メンテナンス中です</div>

        <div class="maintenance-message">
            日進市 雨量・水位観測システムは、現在システムメンテナンス中です。<br>
            メンテナンス中は、観測データを確認することはできません。<br>
            メンテナンス期間終了後、このページを再度読み込みをしてください。
        </div>

        <div class="maintenance-period">
            {{start}} ～ {{end}}(予定)
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
  data() {
    return {
      start:'',
      end:''
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    getInfo(){
      axios.get('status.json')
      .then((response) => {
        if(response.data.env == 'production'){
          this.$router.push({name: 'Home'})
        }else{

          moment.locale("ja", {
            weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
            weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
          });

          this.start = moment(response.data.startTime).format('Y年M月D日(ddd) H:mm')
          const stime = moment(response.data.startTime).format('Y/M/D')
          const etime = moment(response.data.endTime).format('Y/M/D')

          if(stime == etime){
            this.end = moment(response.data.endTime).format('H:mm')
          }else {
            this.end = moment(response.data.endTime).format('Y年M月D日(ddd) H:mm')
          }          
        }
      })
      .catch(() =>{
        this.$router.push({name: 'Maintenance'})
      })
    },
  }
}
</script>

<style scoped>
  .maintenance-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .maintenance-inner {
    padding: 20px;
    box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.4);
    background: #fff;
  }
  .maintenance-title {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
  }
  .maintenance-message {
    padding: 10px 0 20px 0;
    line-height: 1.8em;
  }
  .maintenance-period {
    text-align: center;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
  }
</style>
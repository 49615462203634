import { Line, mixins } from "vue-chartjs"
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["options"],
  mounted () {

    this.addPlugin({
      getFontSize: function(c)
      {
        let width = c.chart.width
        let size = Math.round(width / 40)
        
        if(size < 10) {
          size = 10
        }else if(size > 14) {
          size = 14
        }
        return size
      },
      beforeLayout: function (c) {
        c.scales["y-axis-0"].options.ticks.fontSize = this.getFontSize(c)
      },
      beforeDraw: function (c) {

        let chart = c.chart
        let xscale = chart.scales["x-axis-0"]
        let yscale = chart.scales["y-axis-0"]
        let left = xscale.left

        // 危険
        let lv2_top = yscale.getPixelForValue(yscale.max)
        let lv2_btm = yscale.getPixelForValue(c.options.danger_lv)
        let lv2_height = lv2_btm - lv2_top
        chart.ctx.fillStyle = "rgba(255, 229, 229, 1)"
        chart.ctx.fillRect(left, lv2_top, xscale.width, lv2_height)

        // 注意
        let lv1_top = yscale.getPixelForValue(c.options.danger_lv)
        let lv1_btm = yscale.getPixelForValue(c.options.caution_lv)
        let lv1_height = lv1_btm - lv1_top
        chart.ctx.fillStyle = "rgba(254, 246, 231, 1)"
        chart.ctx.fillRect(left, lv1_top, xscale.width, lv1_height)

        // 平常
        let lv0_top = yscale.getPixelForValue(c.options.caution_lv)
        let lv0_btm = yscale.getPixelForValue(yscale.min)
        let lv0_height = lv0_btm - lv0_top
        chart.ctx.fillStyle = "rgba(244, 250, 246, 1)"
        chart.ctx.fillRect(left, lv0_top, xscale.width, lv0_height)

        for(let i = 0; i <= xscale._ticks.length; i++){
          if(i%3 !== 0){
            let additional = xscale.getPixelForValue(i)
            chart.ctx.lineWidth = 1.5
            chart.ctx.beginPath()
            chart.ctx.setLineDash([2, 2])
            chart.ctx.moveTo(additional, lv2_top)
            chart.ctx.lineTo(additional, lv0_btm)
            chart.ctx.strokeStyle = "#ffffff"
            chart.ctx.stroke()
          }
        }

        for(let j = 0; j <= xscale._ticks.length; j++){
          if(j%3 == 0){
            let line = xscale.getPixelForValue(j)
            chart.ctx.lineWidth = 1
            chart.ctx.beginPath()
            chart.ctx.setLineDash([])
            chart.ctx.moveTo(line, lv2_top)
            chart.ctx.lineTo(line, lv0_btm)
            chart.ctx.strokeStyle = "#cccccc"
            chart.ctx.stroke()
          }
        }
        
        // X軸を間引く
        for(let n in chart.scales["x-axis-0"]._ticks){
          if(n%3 !== 0){
            chart.scales["x-axis-0"]._ticks[n].label = ""
          }
        }

        for(let p in chart.scales["y-axis-0"]._ticks){

          chart.ctx.beginPath()
          let line = yscale.getPixelForValue(chart.scales["y-axis-0"]._ticks[p].value)
          chart.ctx.lineWidth = 1
          chart.ctx.moveTo(chart.chartArea.left, line)
          chart.ctx.lineTo(chart.chartArea.right, line)
          chart.ctx.strokeStyle = "#cccccc"
          chart.ctx.stroke()

          // 横線2
          chart.ctx.beginPath()
          let line2 = yscale.getPixelForValue(chart.scales["y-axis-0"]._ticks[p].value)
          chart.ctx.lineWidth = 1
          chart.ctx.moveTo(chart.chartArea.left-5, line2)
          chart.ctx.lineTo(chart.chartArea.left, line2)
          chart.ctx.strokeStyle = "#cccccc"
          chart.ctx.stroke()
        }

        // 線
        chart.ctx.beginPath()
        chart.ctx.setLineDash([])
        chart.ctx.fillStyle = "rgba(255, 0, 0, 1)"
        chart.ctx.fillRect(left, lv2_btm, xscale.width, 2)
        chart.ctx.fillStyle = "rgba(244, 170, 24, 1)"
        chart.ctx.fillRect(left, lv1_btm, xscale.width, 2)

      },
      afterDatasetsDraw: function (c) {

        let chart = c.chart
        let size = this.getFontSize(c)
        let xaxis = chart.scales["x-axis-0"]

        chart.ctx.font = size+"px 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'Osaka', 'ＭＳ Ｐゴシック', 'MS P Gothic', 'sans-serif'"
        chart.ctx.fillStyle = "#575757"

        let labels = []
        for(let j in xaxis.ticks) {
          labels.push(xaxis.ticks[j])
        }

        let padding = 12
        if (window.matchMedia("(max-width: 576px)").matches) {
          padding = 8
        }

        let cnt = xaxis["_gridLineItems"].length - 1

        for(let i in xaxis["_gridLineItems"]) {
          
          let measure = chart.ctx.measureText(labels[i])
          let x = xaxis["_gridLineItems"][i]
          
          if(labels[i]){
            // 最後のみ位置調整
            if(cnt == i){
              chart.ctx.fillText(labels[i], x.tx2 - (measure.width/2) - 8, x.ty2 + padding)
            }else {
              if(i%3 == 0){
                chart.ctx.fillText(labels[i], x.tx2 - (measure.width/2), x.ty2 + padding)
              }
            }
          }

        }

      }
    });

    this.renderChart(this.chartData, this.options)

  }
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./assets/scss/bootstrap-custom.scss"
import 'leaflet/dist/leaflet.css'

require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
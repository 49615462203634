<template>
<div class="common-h100">
    <div class="content-title">累積雨量・帳票
        <div>
            <button class="tab-btn" v-on:click="changeTab(0)" v-bind:class="{ active: tab==0 }">累積</button>
            <button class="tab-btn" v-on:click="changeTab(1)" v-bind:class="{ active: tab==1 }">帳票</button>
        </div>
    </div>

    <div class="common-body">
        <div class="common-inner-fix">

            <div class="common-ledger-container">
            
                <div class="common-ledger-inner" ref="ledger_scroll">

                    <div v-if="!tab">
                        <div class="shadow-bottom" v-show="is_shadow1"></div>
                    </div>
                    <div v-else>
                        <div class="shadow-bottom" v-show="is_shadow2"></div>
                    </div>

                    <div class="ledger-table-fix" v-show="!tab">
                        <table class="ledger-table" v-bind:style="{ width: tb1_w + 'px' }">
                            <tbody>
                                <tr>
                                    <th width="33.3%">降り始め</th>
                                    <th width="33.3%">降り終り</th>
                                    <th width="33.3%">総雨量</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
      
                    <div class="ledger-table-scroll" v-show="!tab">                   
                        <table class="ledger-table" v-show="data.points[0].totalArchive.length" ref="tb1">
                            <tbody>
                                <tr v-for="(item,index) in data.points[0].totalArchive" :key="index">
                                    <td width="33.3%" ref="testx">{{getDate(item.startTime, "M月D日 HH:mm")}}</td>
                                    <td width="33.3%">{{(item.endTime!==null)?getDate(item.endTime, "M月D日 HH:mm"):"-"}}</td>
                                    <td width="33.3%">{{(item.data!==null)?formatDecimal(item.data)+" mm":"欠測"}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div class="ledger-table-fix" v-show="tab">
                        <table class="ledger-table" v-bind:style="{ width: tb2_w + 'px' }">
                            <tbody>
                                <tr>
                                    <th width="50%">時：分</th>
                                    <th width="50%">雨量</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="ledger-table-scroll" v-show="tab">
                        <table class="ledger-table" ref="tb2">
                            <tbody>
                                <tr v-for="(item,index) in data.points[0].hourlyArchive" :key="index">
                                    <td width="50%"><span class="latest-icon" v-show="index==0">最新</span>{{(item.datetime!==null)?getDate(item.datetime, "HH:mm"):"-"}}</td>
                                    <td width="50%">{{(item.data!==null)?formatDecimal(item.data)+" mm":"欠測"}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
            
            </div>

        </div>

    </div>
</div>
</template>

<script>

import moment from "moment"

export default {
  props: ["data"],
  data() {
    return {
      tab: 0,
      is_shadow1: false,
      is_shadow2: false,
      tb1_w: 0,
      tb2_w: 0
    };
  },
  methods: {
    formatDecimal(num) {
      if(num == null){
        return null
      }
      return num.toFixed(1)
    },
    getDate(date, format) {
      return moment(date).format(format)
    },
    changeTab(num) {
      this.tab = num
      this.setTableHead()
    },
    setTableHead() {

      this.$nextTick(function(){

        if(this.$refs.ledger_scroll && this.$refs.tb1 && this.$refs.tb2){
          const scroll = this.$refs.ledger_scroll.getBoundingClientRect()
          const table1 = this.$refs.tb1.getBoundingClientRect()
          const table2 = this.$refs.tb2.getBoundingClientRect()

          if(table1.width > 0){
            this.tb1_w = table1.width
          }else {
            this.tb1_w = scroll.width - 2
          }

          this.tb2_w = table2.width

          if(!this.tab) {
            if(scroll.height-30-36 <= table1.height){
              this.is_shadow1 = true
            }else {
              this.is_shadow1 = false
            }
          }else {
            if(scroll.height-30-36 <= table2.height){
              this.is_shadow2 = true
            }else {
              this.is_shadow2 = false
            }
          }
        }

      });

    }
  },
  mounted() {
    this.setTableHead()
    window.addEventListener("resize", this.setTableHead)
  }
}
</script>
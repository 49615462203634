import axios from 'axios'
import moment from "moment"
import noimage from "@/assets/images/noimage.jpg"

export const NaviMixin =  {
  data() {
    return {
      navi_active: "river",
      json: null,
      river_level: null,
      river_msg: "-",
      river_msg_sp: "-",
      rain_level: null,
      rain_msg: "-"
    }
  },
  methods: {
    replaceImage(e) {
      e.target.src = noimage
    },
    setRiverLevel(val) {
      if(val == null){
        this.river_level = null
      }else if(val >= -1.39) {
        this.river_level = 2
        this.river_msg = "洪水の危険が\n差し迫っています"
        this.river_msg_sp = "洪水の危険が\n差し迫っています"
      } else if(val >= -2.9) {
        this.river_level = 1
        this.river_msg = "今後の水位の動向に注意が必要です"
        this.river_msg_sp = "今後の水位の動向に\n注意が必要です"
      }else {
        this.river_level = 0
        this.river_msg = "-"
        this.river_msg_sp = "-"
      }
    },
    setRainLevel(val) {
      if(val == null){
        this.rain_level = null
      }else if(val >= 40) {
        this.rain_level = 2
      } else if(val >= 20) {
        this.rain_level = 1
      }else {
        this.rain_level = 0
      }
    },
    setRainMessage(data) {
      let latest = data.totalArchive[0]
      if(latest && latest.endTime == null && latest.startTime && latest.data){
        const dateTo = moment(latest.startTime)
        const dateFrom = moment()
        const h = dateFrom.diff(dateTo, 'hours')
        let period = h+"時"
        if(h >= 24) {
          period = dateFrom.diff(dateTo, 'days')+"日"
        }else if(h < 1) {
          period = dateFrom.diff(dateTo, 'minute')+"分"
        }
        this.rain_msg = moment(latest.startTime).format("M/D HH:mm")+"から連続で約"+period+"間降り続いています"
      }
    }
  },
  mounted() {
    moment.locale("ja");
    axios.get('data.json').then(response => {
      if(response.status){
        this.json = response.data
        this.setRiverLevel(response.data.river.points[0].current)
        this.setRainLevel(response.data.rain.points[0].hourly)
        this.setRainMessage(response.data.rain.points[0])
        if(this.scrollTarget){
          this.$nextTick(() => {
            this.$scrollTo("#"+this.scrollTarget)
          })
        }
      }
    })
  }
}
<template>
<div class="home">

    <div class="sp-header">
        <div class="sp-top">
            <a href="."><img src="@/assets/images/sp-logo.svg" alt="日進市 雨量・水位観測システム"></a>
            <a href="" class="reload-btn"></a>
        </div>
    </div>

    <div class="container-disclaimer">
        <nav>

            <a href="." class="logo">
                <img src="@/assets/images/logo.svg" alt="日進市 雨量・水位観測システム">
            </a>

            <ol class="navi">
                <li v-on:click="setScroll('river')" class="pointer" v-bind:class="{ active: (navi_active=='river')?true:false }">
                      <div class="navi-border">
                          <div class="navi-title"><img src="@/assets/images/icon-river.svg" alt="">河川水位</div>
                          
                          <div class="navi-level-container">
                              <div class="navi-level" v-bind:class="`lv${river_level}`">
                                  <div class="navi-level-icon" v-bind:class="`lv${river_level}`"></div>
                                  <div class="navi-level-label">
                                      <span v-if="river_level==null">欠 測</span>
                                      <span v-if="river_level==0">平 常<br>水 位</span>
                                      <span v-if="river_level==1">注 意<br>水 位</span>
                                      <span v-if="river_level==2">危 険<br>水 位</span>
                                  </div>
                              </div>
                              <div class="navi-msg" v-bind:class="{ none: (river_level==null)?true:false }"><span>{{river_msg}}</span></div>
                          </div>
                    </div>
                </li>
                <li v-on:click="setScroll('rain')" class="pointer" v-bind:class="{ active: navi_active=='rain' }">
                    <div class="navi-border">
                        <div class="navi-title">
                            <img src="@/assets/images/icon-rain.svg" alt="">雨量観測
                        </div>

                        <div class="navi-level-container">
                            <div class="navi-level" v-bind:class="`lv${rain_level}`">
                                  <div class="navi-level-icon" v-bind:class="`lv${rain_level}`"></div>
                                  <div class="navi-level-label">
                                      <span v-if="rain_level==null">欠 測</span>
                                      <span v-if="rain_level==0">平 常<br>雨 量</span>
                                      <span v-if="rain_level==1">注 意<br>雨 量</span>
                                      <span v-if="rain_level==2">警 戒<br>雨 量</span>
                                  </div>
                            </div>
                            <div class="navi-msg" v-bind:class="{ none: rain_msg=='-' }"><span>{{rain_msg}}</span></div>
                        </div>
                    </div>
                </li>
                <li  v-on:click="setScroll('camera')" class="pointer" v-bind:class="{ active: navi_active=='camera' }">
                    <div class="navi-border">
                        <div class="navi-title">
                            <img src="@/assets/images/icon-camera.svg" alt="">河川カメラ
                        </div>
                        <div class="navi-camera">
                            <img v-bind:src="json.camera.points[0].minutelyArchive[0].url" alt="河川カメラ画像" v-if="json&&json.camera.points[0].minutelyArchive[0].url!==null" @error="replaceImage">
                            <img src="@/assets/images/noimage.jpg" alt="河川カメラ画像" v-if="json&&json.camera.points[0].minutelyArchive[0].url==null">
                        </div>
                    </div>
                </li>
                <li>
                    <Weather :data="json.weather" v-if="json" />
                </li>
                <li>
                    <Map />
                </li>
            </ol>

            <router-link to="/disclaimer" class="term-btn">免責事項</router-link>
            
        </nav>

        <div class="main">

            <div class="category"><h2>免責事項</h2></div>

            <div class="row">
                <div class="col-12 mt-20">
                    <div class="common-container">
                        <div class="disclaimer-container">

                            <router-link to="/" class="disclaimer-btn">← 戻る</router-link>

                            <div class="disclaimer-title"><h3>本システムの掲載データについて</h3></div>
                            
                            <p>
                              日進市野方町後口（野方橋付近）に設置した観測装置で計測した水位情報、雨量情報、風速情報、カメラで撮影した河川画像を配信しています。<br>
                              <br>
                              日進市ホームページ日進市雨量水位観測システム<br>
                              <a href="https://www.city.nisshin.lg.jp/department/seikatu/bousai/5/2/2/bousaijouhou/11901.html" target="_blank">https://www.city.nisshin.lg.jp/department/seikatu/bousai/5/2/2/bousaijouhou/11901.html</a>
                            </p>

                            <div class="disclaimer-title"><h3>利用上の注意について</h3></div>
                            
                            <p>
                              本システムでは、日進市が観測している雨量・水位および映像の情報を提供しています。<br>
                              データはあくまでも参考値であり、水防の警報等の発令を意味するものではありません。<br>
                              <br>
                              本システムでは、無人の観測装置から自動的に送られてくるデータを、観測後直ちにお知らせしています。<br>
                              そのため、観測機器の故障など不測の事態が発生した場合、一時的に異常な値が表示される場合がありますので、あらかじめご了承ください。<br>
                              <br>
                              本サイトの利用は、著作権法に定める個人的な目的に利用する範囲に限らせていただきます。<br>
                              本サイト内にて提供されている機能以外の利用、及び公開を行う媒体を問わず再配布（二次配布）は禁止します。
                            </p>

                            <div class="disclaimer-title"><h3>推奨ブラウザ環境について</h3></div>
                            
                            <p>
                              当サイトは以下の環境で閲覧することを推奨します。<br><br>
                              【パソコン/スマートフォン】<br>
                              Microsoft Edge 最新版<br>
                              Mozilla Firefox 最新版<br>
                              Google Chrome 最新版<br>
                              Safari 最新版<br><br>
                              ※推奨環境以外で利用された場合や、推奨環境下でもご利用のブラウザの設定等によっては、正しく表示されない場合があります。
                            </p>

                            <div class="disclaimer-title"><h3>問い合わせ先</h3></div>
                            
                            <p>
                              本システムに関する問い合わせは、日進市防災交通課までお願いします。
                            </p>

                            <router-link to="/" class="disclaimer-btn">← 戻る</router-link>

                        </div>

                    </div>
                </div>

            </div>

        </div>

    </div>

</div>
</template>

<script>

import Weather from '@/components/Weather.vue'
import Map from '@/components/Map.vue'
import {NaviMixin} from '@/mixins/navi.js'

export default {
  mixins: [NaviMixin],
  name: 'Navigation',
  components: {
    Weather,
    Map
  },
  methods: {
    setScroll(target) {
      this.$router.push({name: 'Home', params: {scrollTarget: target}})
    }
  }
}
</script>
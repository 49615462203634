<template>
<div class="common-h100">
    <div class="content-title">過去の水位（12時間）
        <div>
            <button class="tab-btn" v-on:click="changeTab(0)" v-bind:class="{ active: tab==0 }">グラフ</button>
            <button class="tab-btn" v-on:click="changeTab(1)" v-bind:class="{ active: tab==1 }">帳票</button>
        </div>
    </div>

    <div class="common-body" ref="inner">

        <div class="common-inner" v-show="!tab">
            <river-line-chart v-if="loaded" :chart-data="datacollection" :options="options" :styles="{width:w+'px', height: h+'px', position: 'relative'}"></river-line-chart>
            <ol class="legend">
                <li><div class="legend-line rain"></div>水面の位置</li>
                <li><div class="legend-line caution"></div>注意水位（{{formatDecimal(data.points[0].cautionLv)}}m）</li>
                <li><div class="legend-line danger"></div>危険水位（{{formatDecimal(data.points[0].dangerLv)}}m）</li>
            </ol>
        </div>

        <div class="common-inner-fix" v-show="tab">
            <div class="common-ledger-container">
                <div class="common-ledger-inner">
                    <div class="shadow-bottom"></div>

                    <div class="ledger-table-fix">
                        <table class="ledger-table" v-bind:style="{ width: tb_w + 'px' }">
                            <tbody>
                                <tr>
                                    <th width="50%">時：分</th>
                                    <th width="50%">水位</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="ledger-table-scroll">
                        <table class="ledger-table" ref="tb">
                            <tbody>
                                <tr v-for="(item,index) in river_archive" :key="index">
                                    <td width="50%"><span class="latest-icon" v-show="index==0">最新</span>{{getDate(item.datetime,"HH:mm")}}</td>
                                    <td width="50%">{{(item.data!==null)?formatDecimal(item.data)+" m":"欠測"}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>

import RiverLineChart from "./RiverLineChart.js"
import moment from "moment"

export default {
  props: ["data"],
  components: {
    RiverLineChart
  },
  data() {
    return {
      datacollection: { labels:[], datasets: [] },
      options: null,
      tab: 0,
      river_archive: null,
      h: 300,
      w: 350,
      tb_w: 0,
      loaded: false
    }
  },
  methods: {
    formatDecimal(num) {
      if(num == null){
        return null
      }
      return num.toFixed(2)
    },
    getDate(date, format) {
      return moment(date).format(format)
    },
    changeTab(num) {
      this.tab = num
      this.setChartHeight()
    },
    setChartHeight() {

      if(this.$refs.inner){
        const rect = this.$refs.inner.getBoundingClientRect()
        if(window.matchMedia("(max-width: 576px)").matches){
          this.w = rect.width - 20
        }else {
          this.w = rect.width*0.88
        }
      }

      this.$nextTick(function(){
        if(this.$refs.tb){
          const table = this.$refs.tb.getBoundingClientRect()
          this.tb_w = table.width
        }
      });

      if(window.matchMedia("(max-width: 576px)").matches){
        this.h = 180
      }else {
        this.h = 300
      }
    }
  },
  mounted() {

    this.loaded = false
    this.setChartHeight()
    window.addEventListener("resize", this.setChartHeight)

    let level = []
    let date = []

    if(this.data){
            
      this.river_archive = this.data.points[0].archive
      for(let i in this.data.points[0].archive){
        date.push(moment(this.data.points[0].archive[i].datetime).format("HH:mm"))
        level.push(this.data.points[0].archive[i].data)
      }

      this.datacollection = {
        labels: date.reverse(),
        datasets: [{
          data: level.reverse(),
          borderColor: "rgba(9, 109, 173, 1)",
          borderWidth: 1,
          pointBackgroundColor: "rgba(255, 255, 255, 1)",
          fill: false
        }]
      }

      this.options = {
        caution_lv: this.data.points[0].cautionLv,
        danger_lv: this.data.points[0].dangerLv,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        elements: {
          line: {
            tension: 0
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 10,
            top: 20,
            bottom: 0
          }
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return " " + parseFloat(tooltipItem.value).toFixed(2) + " m"
            }
          }
        },
        scales: {
          xAxes: [{
            ticks: {
              minRotation: 0,
              maxRotation: 0,
              fontSize: 8,
              fontColor: "rgba(0, 0, 0, 0)",
              fontFamily: "'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'Osaka', 'ＭＳ Ｐゴシック', 'MS P Gothic', 'sans-serif'",
            },
            gridLines: {
              zeroLineColor: "rgba(0, 0, 0, 0)",
              color: "rgba(0, 0, 0, 0)"
            }
          }],
          yAxes: [{
            ticks: {
              max: 0,
              min: -4,
              fontSize: 8,
              fontColor: "#575757",
              fontFamily: "'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'Osaka', 'ＭＳ Ｐゴシック', 'MS P Gothic', 'sans-serif'",
              stepSize: 1,
              callback: function(value) {
                return parseFloat(value).toFixed(2)
              }
            },
            gridLines: {
              display: false
            }
          }]
        }
      }

      this.loaded = true

    }
      
  }
};
</script>